window.processRuntime = {
  env: {
    NODE_ENV: process.env.NODE_ENV,
    MAPBOX_ACCESS_TOKEN: process.env.MAPBOX_ACCESS_TOKEN,
    MAPBOX_PLACE_URL: process.env.MAPBOX_PLACE_URL,
    KOUMOUL_KEY: process.env.KOUMOUL_KEY,
    GEO_KEY: process.env.GEO_KEY,
    API_URL: process.env.API_URL,
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_CUSTOM_CLAIM_DOMAIN: process.env.AUTH0_CUSTOM_CLAIM_DOMAIN,
    AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
    YOUSIGN_BASE_URL: process.env.YOUSIGN_BASE_URL,
    QUERY_KEY_CONFIRMATION_TOKEN: process.env.QUERY_KEY_CONFIRMATION_TOKEN,
    QUERY_KEY_USER_EMAIL: process.env.QUERY_KEY_USER_EMAIL,
    TUNNEL_URL: process.env.TUNNEL_URL,
    USER_SESSION_REFRESH_DELAY: process.env.USER_SESSION_REFRESH_DELAY,
    FAKE_META_STEP_ID: process.env.FAKE_META_STEP_ID,
    DRAFT_META_STEP_ID: process.env.DRAFT_META_STEP_ID,
    YOUSIGN_SIGNATURE_UI_ID: process.env.YOUSIGN_SIGNATURE_UI_ID,
    SENTRY_DSN: process.env.SENTRY_DSN,
    SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
    PAYMENT_TUNNEL_BASE_URL: process.env.PAYMENT_TUNNEL_BASE_URL,
    HP_BASE_URL: process.env.HP_BASE_URL,
    XENO_KEY: process.env.XENO_KEY,
    CONFIG_GTM_ID: process.env.CONFIG_GTM_ID,
    ONBOARDING: process.env.ONBOARDING,
    FACILITATION: process.env.FACILITATION,
    SALES: process.env.SALES,
    DEALSTAGE_PAIEMENT: process.env.DEALSTAGE_PAIEMENT,
    HUBSPOT_FORM_WAITING_STAGE_ID: process.env.HUBSPOT_FORM_WAITING_STAGE_ID,
    HUBSPOT_PIPELINE_STAGES_BTOC_BTOBTOC_ARTISAN: JSON.parse(process.env.HUBSPOT_PIPELINE_STAGES_BTOC_BTOBTOC_ARTISAN),
    HUBSPOT_PIPELINE_STAGES_ONBOARDING: JSON.parse(process.env.HUBSPOT_PIPELINE_STAGES_ONBOARDING),
    HUBSPOT_PIPELINE_STAGES_PRODUCTION: JSON.parse(process.env.HUBSPOT_PIPELINE_STAGES_PRODUCTION),
    HUBSPOT_PIPELINE_STAGES_CONNECTION: JSON.parse(process.env.HUBSPOT_PIPELINE_STAGES_CONNECTION),
    HUBSPOT_PIPELINE_STAGES_SALES: JSON.parse(process.env.HUBSPOT_PIPELINE_STAGES_SALES),
    GOOGLE_API_BASE_URL: process.env.GOOGLE_API_BASE_URL,
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    MAKE_HEMEA_SCENARIO: process.env.MAKE_HEMEA_SCENARIO,
    MAKE_CIC_SCENARIO: process.env.MAKE_CIC_SCENARIO,
    LUKO_PARTNER_URL: process.env.LUKO_PARTNER_URL,
    HUBSPOT_SALES_URL: process.env.HUBSPOT_SALES_URL,
    TALLY_BASE_URL: process.env.TALLY_BASE_URL,
    TYPEFORM_DOMAIN: process.env.TYPEFORM_DOMAIN,
    SELF_URL: process.env.SELF_URL,
    ZENDESK_API_TOKEN: process.env.ZENDESK_API_TOKEN
  },
}
